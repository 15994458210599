import { Link } from "gatsby"
import React from "react"
//@ts-ignore
import { PageConsumer } from "./layout"

/*
interface LocalizedLinkProps {
  to: string;
  onClick?: (e: any) => void;
  withoutPreload?: boolean;
  openInNewWindow?: boolean;
  disableLink?: boolean;
  children?: ReactNode;
  [x: string]: any;
}
*/

export const LocalizedLink = (
  {
    to,
    withoutPreload,
    openInNewWindow,
    disableLink,
    ...props
  } /*: LocalizedLinkProps*/
) =>
  disableLink ? (
    <>{props.children}</>
  ) : (
    <PageConsumer>
      {({ locale, location }) => {
        const isHttpLink = to?.startsWith("https://")
        const target = isHttpLink ? "_blank" : undefined

        let path = to
        if (!isHttpLink && (location.pathname ?? "")?.includes("/en/")) {
          path = to?.startsWith("/") ? "/en" + to : "/en/" + to
        }

        // const path = isHttpLink
        //   ? to
        //   : locales[locale].default
        //   ? to
        // : `/${locale}${to}`;

        if (withoutPreload || openInNewWindow) {
          return (
            <a
              target={openInNewWindow ? "_blank" : target}
              {...props}
              href={path}
            />
          )
        }
        return (
          <Link
            target={openInNewWindow ? "_blank" : target}
            {...props}
            to={path}
          />
        )
      }}
    </PageConsumer>
  )

//
export const LocalizedSwitch = (
  {
    currentPath,
    disabled,
    ...props
  } /*: {
  disabled?: boolean;
  onClick?: (e: any) => void;
  currentPath?: string;
  children: any;
  className?: string;
  style?: CSSProperties;
}*/
) => {
  if (disabled) {
    return props.children
  }

  return (
    <PageConsumer>
      {({ locale, location }) => {
        const newLocale = locale === "it" ? "en" : "it"

        let path =
          (location.pathname ?? "")?.replace(`/en/`, "/") +
          location.search +
          location.hash

        if (!(location.pathname ?? "")?.includes("/en/")) {
          path = "/en" + path
        }

        // const to = location.pathname.replace(`/en/`, "/");
        // const to = location.pathname.replace(`/${locale}/`, "/");
        // let path = locales[newLocale].default ? to : `/${newLocale}${to}`;

        return (
          <Link
            {...props}
            onClick={e => {
              if (props.onClick) props.onClick(e)
              if (typeof window !== `undefined`)
                localStorage.setItem("localization", newLocale)
            }}
            to={path}
          />
        )
      }}
    </PageConsumer>
  )
}
